<script lang="ts">
    import LL from "../../../../i18n/i18n-svelte";
    import { createEventDispatcher } from "svelte";
    import { onMapEditorInputFocus, onMapEditorInputUnfocus } from "../../../Stores/MapEditorStore";
    const dispatch = createEventDispatcher();

    function onValueChange() {
        dispatch("change");
    }
    export let property: string;
</script>

<div class="value-input">
    <textarea
        type="text"
        placeholder={$LL.mapEditor.entityEditor.textProperties.placeholder()}
        rows="5"
        bind:value={property}
        on:change={onValueChange}
        on:focus={onMapEditorInputFocus}
        on:blur={onMapEditorInputUnfocus}
    />
</div>

<style lang="scss">
    .value-input {
        display: flex;
        width: 100%;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        align-items: center;
        textarea {
            flex-grow: 1;
            min-width: 0;
        }
        * {
            margin-bottom: 0;
        }
    }
</style>
